<template>
  <div>
    <div class="language-menu-container">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div class="language-menu-content" v-bind="attrs" v-on="on">
            <span class="language-menu-text">
              {{ lang === "pt" ? "PT-BR" : "EN-US" }}
            </span>
            <v-icon class="language-menu-icon">mdi-chevron-down</v-icon>
          </div>
        </template>
        <v-list :tile="false" class="language-menu-list" nav>
          <v-list-item
            class="language-menu-list-item"
            v-for="(item, index) in items"
            :key="index"
            @click="changeLanguage(item)"
          >
            <v-list-item-title v-text="item.text" />
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitch",
  props: {
    lang: { type: String, default: "PT-BR" },
  },
  data: () => ({
    items: [
      { text: "PT-BR", value: "pt" },
      { text: "EN-US", value: "en" },
    ],
  }),
  methods: {
    changeLanguage(item) {
      this.$store.commit("SET_LANGUAGE", item.value);
    },
  },
};
</script>

<style scoped>
.language-menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--primary);
  height: 100%;
  align-items: center;
}

.language-menu-container:hover .language-menu-text {
  text-decoration: underline;
}

.language-menu-container:hover .language-menu-icon {
  text-decoration: underline;
}

.language-menu-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--white);
}

.language-menu-content {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.language-menu-icon {
  color: var(--primary);
}

.language-menu-list {
}
.language-menu-list-item {
  cursor: pointer;
}

@media only screen and (max-width: 1020px) {
  .language-menu-text {
    color: #1a56db;
  }

  .language-menu-text:hover,
  .language-menu-text:active {
    color: var(--primary-hover) !important;
    text-decoration: underline;
  }
}
</style>
