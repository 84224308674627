<template>
  <Slide class="side-menu" left>
    <a class="dxa-link" :href="`/auth`">{{ $t("login") }}</a>
    <a class="dxa-link" @click="handlePushRouter">{{ $t("create_wallet") }}</a>
    <LanguageSwitch :lang="lang" />
  </Slide>
</template>

<script>
import { Slide } from "vue-burger-menu";
import LanguageSwitch from "@/components/dxa/LanguageSwitch/LanguageSwitch.vue";
import { getAlphaRegisterUrlLang } from "@/shared/helpers/alphaRegisterHelpers";
import { mapState, mapMutations } from "vuex";

// esse cara não está no @components pq ele é bem especifico da landing page
export default {
  name: "SideMenu",
  components: {
    Slide,
    LanguageSwitch,
  },
  props: {
    createWalletLink: String,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      getAlphaRegisterUrlLang,
    };
  },
  created() {
    this.checkLanguage();

    const pagePathLang = this.getAlphaRegisterUrlLang(pagePath);
    if (pagePathLang) {
      this.$i18n.locale = pagePathLang;
      this.$store.commit("SET_LANGUAGE", pagePathLang);
    } else {
      this.$i18n.locale = "pt";
      this.$store.commit("SET_LANGUAGE", "pt");
    }
  },
  beforeDestroy() {
    this.setAppBar(true);
  },
  computed: {
    ...mapState(["lang"]),
  },
  methods: {
    ...mapMutations({
      setAppBar: "SET_APP_BAR",
    }),
    checkLanguage() {
      let { lang } = this.$route.query;
      if (lang) {
        this.$store.commit("SET_LANGUAGE", lang);
      } else {
        let localStorageLang = window.localStorage.getItem("lang");
        if (!localStorageLang) {
          this.$store.commit("SET_LANGUAGE", "pt");
        }
      }
    },
    handlePushRouter() {
      this.$emit("createWalletLink");
    },
  },
};
</script>

<style>
.bm-menu {
  background-color: var(--dark);
}

.bm-burger-bars {
  background-color: var(--white);
  height: 3px;
}

.bm-cross {
  background-color: var(--primary);
}

.bm-burger-button {
  /* top: unset; */
  left: 20px;
  /* right: unset; */
}

.bm-item-list {
  height: 12%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
</style>

<style scoped>
.dxa-link {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: var(--primary);
  margin-left: 5px;

  text-decoration: none;
}

a.dxa-link:hover {
  color: var(--primary-hover);
  text-decoration: underline;
}

a.dxa-link:active {
  color: var(--primary-focus);
  text-decoration: underline;
}
</style>
